import React from 'react';
import { useTranslation } from 'react-i18next';

function CookiePolicy() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto p-6 max-w-4xl bg-gray-800 text-white rounded mt-10">
      <h1 className="text-3xl font-bold mb-6">{t('cookie_policy_title')}</h1>
      <p className="mb-4">{t('cookie_policy_intro')}</p>

      <h2 className="text-2xl font-semibold mb-4">{t('cookie_what_are_cookies_title')}</h2>
      <p className="mb-4">{t('cookie_what_are_cookies_text')}</p>

      <h2 className="text-2xl font-semibold mb-4">{t('cookie_types_title')}</h2>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>{t('cookie_types.necessary.title')}:</strong> {t('cookie_types.necessary.description')}
        </li>
        <li>
          <strong>{t('cookie_types.performance.title')}:</strong> {t('cookie_types.performance.description')}
        </li>
        <li>
          <strong>{t('cookie_types.functionality.title')}:</strong> {t('cookie_types.functionality.description')}
        </li>
        <li>
          <strong>{t('cookie_types.targeting.title')}:</strong> {t('cookie_types.targeting.description')}
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">{t('cookie_management_title')}</h2>
      <p className="mb-4">{t('cookie_management_text')}</p>

      <h2 className="text-2xl font-semibold mb-4">{t('cookie_how_to_change_settings_title')}</h2>
      <p className="mb-4">{t('cookie_how_to_change_settings_text')}</p>

      <h2 className="text-2xl font-semibold mb-4">{t('cookie_more_info_title')}</h2>
      <p>
        {t('cookie_more_info_text')}
        <a 
          href="https://www.aboutcookies.org/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-blue-400 hover:underline"
        >
          {t('cookie_more_info_link')}
        </a>.
      </p>
    </div>
  );
}

export default CookiePolicy;
