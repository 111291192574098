import React, { useState } from 'react';
import { API_BASE_URL } from '../config';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ResetPassword() {
  const { t } = useTranslation();
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError(t('passwords_do_not_match'));
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/auth/reset-password/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage(t('password_reset_success'));
        setError('');
        setTimeout(() => navigate('/login'), 2000); // 2 saniye sonra login sayfasına yönlendirme
      } else {
        setError(data.error || t('reset_failed'));
      }
    } catch (error) {
      console.error(t('reset_error'), error);
      setError(t('reset_error'));
    }
  };

  return (
    <div className="container mx-auto p-6 max-w-md">
      <h2 className="text-3xl font-bold mb-4">{t('reset_password')}</h2>
      <form onSubmit={handleResetPassword} className="bg-gray-800 p-4 rounded">
        <div className="mb-4">
          <label className="block text-white mb-2" htmlFor="password">{t('new_password')}</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-white"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-white mb-2" htmlFor="confirmPassword">{t('confirm_password')}</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-white"
            required
          />
        </div>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full">
          {t('reset_password')}
        </button>
      </form>
      {message && <p className="text-green-500 mt-4">{message}</p>}
    </div>
  );
}

export default ResetPassword;
