import React, { useState } from "react";
import axios from 'axios';

function Siyanobakterj() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  const correctPassword = "5!yAnoBakT3ri_Jel#42"; // Doğru şifre

  const handlePasswordSubmit = () => {
    if (password === correctPassword) {
      setIsAuthenticated(true);
      setError("");
    } else {
      setError("Yanlış şifre, lütfen tekrar deneyin.");
    }
  };

  const handleSendMessage = () => {
    if (input.trim() === "") return;

    const userMessage = { sender: "user", text: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput("");

    axios.get('https://api.smartbettor.app/api/chat?mes='+input)
            .then(response => {
                const aiMessage = { sender: "ai", text: response.data["mes"] };
                setMessages((prevMessages) => [...prevMessages, aiMessage]);
            })
            .catch(error => {
                console.error("There was an error fetching the students!", error);
            });    

    
  };

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-lg font-bold mb-4">Şifre Gerekli</h2>
          <input
            type="password"
            className="p-2 border rounded-lg shadow-sm w-full mb-4 focus:outline-none focus:ring-2 focus:ring-blue-600 text-black"
            placeholder="Şifreyi girin..."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handlePasswordSubmit()}
          />
          <button
            onClick={handlePasswordSubmit}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-600 focus:outline-none"
          >
            Giriş Yap
          </button>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <div className="flex flex-col h-screen bg-gray-100 shadow-lg rounded-lg">
        {/* Başlık */}
        <div className="p-4 bg-blue-600 text-white text-lg font-bold rounded-t-lg">
          Yapay Zeka Chat
        </div>

        {/* Mesajlar */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.sender === "user" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`px-4 py-2 rounded-lg text-white ${
                  message.sender === "user" ? "bg-blue-500" : "bg-gray-500"
                }`}
              >
                {message.text}
              </div>
            </div>
          ))}
        </div>

        {/* Mesaj Gönderme Alanı */}
        <div className="p-4 bg-white border-t flex items-center">
          <input
            type="text"
            className="flex-1 p-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-600 text-black"
            placeholder="Mesajınızı yazın..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
          />
          <button
            onClick={handleSendMessage}
            className="ml-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-600 focus:outline-none"
          >
            Gönder
          </button>
        </div>
      </div>
    </div>
  );
}

export default Siyanobakterj;
