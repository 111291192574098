import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from '../config';

const EmailVerification = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [step, setStep] = useState(1); // 1: Email, 2: Code
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSendCode = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      // Backend API çağrısı: E-posta doğrulama kodu gönderir
      await axios.post("https://api.smartbettor.app/auth/delete-account-email", { email });
      setMessage("Doğrulama kodu e-postanıza gönderildi!");
      setStep(2); // Kod giriş adımına geçiş
    } catch (err) {
      setError("Bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      // Backend API çağrısı: Kodun doğruluğunu kontrol eder
      await axios.post("https://api.smartbettor.app/auth/delete-account-email", { email, code });
      setMessage("Doğrulama başarılı!");
      setStep(3); // Doğrulama başarı adımı (isteğe bağlı)
    } catch (err) {
      setError("Geçersiz kod. Lütfen tekrar deneyin.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <form
        className="bg-white p-6 rounded shadow-md w-80"
        onSubmit={step === 1 ? handleSendCode : handleVerifyCode}
      >
        <h2 style={{color:"#000"}} className="text-lg font-semibold mb-4">Kişisel bilgilerinizin silinmesi için lütfen uygulamaya kayıtlı e-posta adresiniz ile info@smartbettor.app adresine niyetinizi belirten bir ileti gönderiniz.</h2>
        
        
      </form>
    </div>
  );
};

export default EmailVerification;
