// src/pages/PrivacyPolicy.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';

function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto p-6 max-w-4xl bg-gray-900 text-white rounded mt-10">
      <h2 className="text-3xl font-bold mb-6">{t('privacy_policy_title')}</h2>
      <p className="mb-4">{t('privacy_policy_intro')}</p>
      <div className="bg-gray-800 p-4 rounded space-y-4">
        <h3 className="text-2xl font-semibold mb-2">{t('privacy_policy_content.last_updated')}</h3>
        <p>{t('privacy_policy_content.introduction')}</p>
        <p>{t('privacy_policy_content.data_collection')}</p>

        <h4 className="text-xl font-bold">{t('privacy_policy_content.data_usage_title')}</h4>
        <ul className="list-disc list-inside">
          {t('privacy_policy_content.data_usage', { returnObjects: true }).map((usage, index) => (
            <li key={index}>{usage}</li>
          ))}
        </ul>

        <h4 className="text-xl font-bold">{t('privacy_policy_content.data_sharing_title')}</h4>
        <p>{t('privacy_policy_content.data_sharing')}</p>

        <h4 className="text-xl font-bold">{t('privacy_policy_content.user_rights_title')}</h4>
        <p>{t('privacy_policy_content.user_rights')}</p>

        <h4 className="text-xl font-bold">{t('privacy_policy_content.security_title')}</h4>
        <p>{t('privacy_policy_content.security')}</p>

        <h4 className="text-xl font-bold">{t('privacy_policy_content.cookies_title')}</h4>
        <p>{t('privacy_policy_content.cookies')}</p>

        <h4 className="text-xl font-bold">{t('privacy_policy_content.changes_title')}</h4>
        <p>{t('privacy_policy_content.changes')}</p>

        <h4 className="text-xl font-bold">{t('privacy_policy_content.contact_title')}</h4>
        <p>{t('privacy_policy_content.contact')}</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
