// src/pages/Tools.jsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function PoissonCalculator() {
  const { t } = useTranslation();
  const [homeStrength, setHomeStrength] = useState(1);
  const [awayStrength, setAwayStrength] = useState(1);
  const [homeGoalsProbability, setHomeGoalsProbability] = useState(null);
  const [awayGoalsProbability, setAwayGoalsProbability] = useState(null);

  const calculatePoisson = (mean, value) => {
    const factorial = (n) => (n === 0 ? 1 : n * factorial(n - 1));
    return (Math.pow(mean, value) * Math.exp(-mean)) / factorial(value);
  };

  const calculateProbabilities = () => {
    const meanHomeGoals = homeStrength;
    const meanAwayGoals = awayStrength;

    const homeProbabilities = Array.from({ length: 6 }, (_, i) => calculatePoisson(meanHomeGoals, i));
    const awayProbabilities = Array.from({ length: 6 }, (_, i) => calculatePoisson(meanAwayGoals, i));

    setHomeGoalsProbability(homeProbabilities);
    setAwayGoalsProbability(awayProbabilities);
  };

  return (
    <div className="p-4 bg-gray-700 rounded mb-4">
      <h3 className="text-xl font-semibold mb-4">{t('poisson_distribution')}</h3>
      <div className="mb-4">
        <label className="block mb-2">{t('home_team_strength')}:</label>
        <input
          type="number"
          step="0.1"
          min="0"
          value={homeStrength}
          onChange={(e) => setHomeStrength(Number(e.target.value))}
          className="p-2 w-full bg-gray-800 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">{t('away_team_strength')}:</label>
        <input
          type="number"
          step="0.1"
          min="0"
          value={awayStrength}
          onChange={(e) => setAwayStrength(Number(e.target.value))}
          className="p-2 w-full bg-gray-800 rounded"
        />
      </div>
      <button onClick={calculateProbabilities} className="bg-blue-500 p-2 rounded">
        {t('calculate')}
      </button>
      {homeGoalsProbability && awayGoalsProbability && (
        <div className="mt-4">
          <h4 className="font-semibold mb-2">{t('home_goals_probabilities')}:</h4>
          <ul className="mb-4">
            {homeGoalsProbability.map((prob, index) => (
              <li key={index}>
                {index} {t('goals')}: {(prob * 100).toFixed(2)}%
              </li>
            ))}
          </ul>
          <h4 className="font-semibold mb-2">{t('away_goals_probabilities')}:</h4>
          <ul>
            {awayGoalsProbability.map((prob, index) => (
              <li key={index}>
                {index} {t('goals')}: {(prob * 100).toFixed(2)}%
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

function KellyCriterionCalculator() {
  const { t } = useTranslation();
  const [winProbability, setWinProbability] = useState(0);
  const [odds, setOdds] = useState(0);
  const [reliability, setReliability] = useState(10);
  const [result, setResult] = useState(null);

  const calculateKelly = () => {
    const winProbDecimal = winProbability / 100;
    const adjustedWinProb = winProbDecimal * (reliability / 10);
    const kelly = (adjustedWinProb * (odds - 1) - (1 - adjustedWinProb)) / (odds - 1);
    setResult((kelly * 100).toFixed(2));
  };

  return (
    <div className="p-4 bg-gray-700 rounded mb-4">
      <h3 className="text-xl font-semibold mb-4">{t('kelly_criterion')}</h3>
      <div className="mb-4">
        <label className="block mb-2">{t('win_probability')}:</label>
        <input
          type="number"
          step="0.1"
          min="0"
          max="100"
          value={winProbability}
          onChange={(e) => setWinProbability(Number(e.target.value))}
          className="p-2 w-full bg-gray-800 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">{t('odds')}:</label>
        <input
          type="number"
          step="0.01"
          min="1"
          value={odds}
          onChange={(e) => setOdds(Number(e.target.value))}
          className="p-2 w-full bg-gray-800 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">{t('reliability')}:</label>
        <input
          type="number"
          step="1"
          min="0"
          max="10"
          value={reliability}
          onChange={(e) => setReliability(Number(e.target.value))}
          className="p-2 w-full bg-gray-800 rounded"
        />
      </div>
      <button onClick={calculateKelly} className="bg-blue-500 p-2 rounded">
        {t('calculate')}
      </button>
      {result !== null && <p className="mt-4">{t('investment_amount')} {result}</p>}
    </div>
  );
}

function Tools() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-bold mb-4">{t('tools')}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <PoissonCalculator />
        <KellyCriterionCalculator />
      </div>
    </div>
  );
}

export default Tools;
