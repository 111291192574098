// src/pages/ForgotPassword.jsx
import React, { useState } from 'react';
import { API_BASE_URL } from '../config';
import { useTranslation } from 'react-i18next';

function ForgotPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    // E-posta doğrulama
    if (!validateEmail(email)) {
      setError(t('enter_valid_email'));
      return;
    }

    try {
      // Backend'e şifre sıfırlama isteği gönder
      const response = await fetch(`${API_BASE_URL}/auth/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage(t('reset_instructions_sent'));
        setError('');
      } else {
        setError(data.error || t('reset_failed'));
      }
    } catch (error) {
      console.error(t('reset_error'), error);
      setError(t('reset_error'));
    }
  };

  const validateEmail = (email) => {
    // Basit bir e-posta doğrulama fonksiyonu
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="container mx-auto p-6 max-w-md">
      <h2 className="text-3xl font-bold mb-4">{t('forgot_password')}</h2>
      <form onSubmit={handleForgotPassword} className="bg-gray-800 p-4 rounded">
        <div className="mb-4">
          <label className="block text-white mb-2" htmlFor="email">{t('email')}</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-white"
            required
          />
        </div>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full">
          {t('reset_password')}
        </button>
      </form>
      {message && <p className="text-green-500 mt-4">{message}</p>}
    </div>
  );
}

export default ForgotPassword;
