import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Token'ı URL'den almak için
import { API_BASE_URL } from '../config'; // API URL'nizi buraya ekleyin
import { useTranslation } from 'react-i18next';

function Unsubscribe() {
  const { token } = useParams(); // URL'deki token'ı alıyoruz
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const unsubscribeUser = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/auth/unsubscribe/${token}`, {
          method: 'GET',
        });

        const data = await response.json();
        if (response.ok) {
          setMessage(t('unsubscribe_success')); // Abonelikten çıkış başarılı
        } else {
          setError(data.error || t('unsubscribe_failed'));
        }
      } catch (error) {
        setError(t('unsubscribe_error'));
      }
    };

    // Sayfa yüklendiğinde otomatik olarak istek gönderiliyor
    unsubscribeUser();
  }, [token, t]);

  return (
    <div className="container mx-auto p-6 max-w-md">
      <h2 className="text-3xl font-bold mb-4">{t('unsubscribe_title')}</h2>
      {error ? (
        <p className="text-red-500 mb-4">{error}</p>
      ) : (
        <p className="text-green-500 mb-4">{message}</p>
      )}
    </div>
  );
}

export default Unsubscribe;
