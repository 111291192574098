import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Loading() {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative flex items-center justify-center">
        <div className="w-32 h-32 border-4 border-blue-400 border-solid rounded-full animate-spin-slow border-t-transparent absolute"></div>
        <div className="w-24 h-24 border-4 border-blue-500 border-solid rounded-full animate-spin-medium border-t-transparent absolute"></div>
      </div>
    </div>
  );
}

function BetOfTheDay() {
  const { t, i18n } = useTranslation();
  const [bets, setBets] = useState([]); // bets state'i başlangıçta boş bir dizi
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeGroupTab, setActiveGroupTab] = useState('Bankers'); // Başlangıç olarak Bankers
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBet = async () => {
      setIsLoading(true);
      setError(null);

      console.log(activeGroupTab);

      try {
        const token = localStorage.getItem('token');
        if (activeGroupTab === "Bankers"){
          const response = await fetch(`https://api.smartbettor.app/api/matches/elite_bets`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            
            // Gelen yanıtta 'error' alanı var mı kontrol et
            if (data.error) {
              setError(data.error);
            } else {
              setBets(data); // Bahisleri al
            }
          } else {
            setError(t('unexpected_error')); // Diğer beklenmeyen durumlar için genel hata mesajı
          }
        }else{
          const response = await fetch(`https://api.smartbettor.app/api/matches/get_bets`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            
            // Gelen yanıtta 'error' alanı var mı kontrol et
            if (data.error) {
              setError(data.error);
            } else {
              setBets(data); // Bahisleri al
            }
          } else {
            setError(t('unexpected_error')); // Diğer beklenmeyen durumlar için genel hata mesajı
          }
        }    
      } catch (error) {
        setError(t('connection_error')); // Bağlantı hatası durumları için
      } finally {
        setIsLoading(false);
      }
    };

    fetchBet();
  }, [activeGroupTab]);
  
  const calcStatus = (status, bet_type, home_goal, away_goal) => {

    if(status == "incomplete"){
      return "Sonuç Bekleniyor."
    }else{
      if(bet_type == "2.5 Üst"){
        if(home_goal + away_goal > 2.5){
          return <font style={{color:"green",fontWeight:"bold"}}>Kazandı</font>
        }else{
          return <font style={{color:"red",fontWeight:"bold"}}>Kaybetti</font>
        }
      }else if(bet_type == "2.5 Alt"){
        if(home_goal + away_goal < 2.5){
          return <font style={{color:"green",fontWeight:"bold"}}>Kazandı</font>
        }else{
          return <font style={{color:"red",fontWeight:"bold"}}>Kaybetti</font>
        }
      }else if(bet_type == "3.5 Üst"){
        if(home_goal + away_goal > 3.5){
          return <font style={{color:"green",fontWeight:"bold"}}>Kazandı</font>
        }else{
          return <font style={{color:"red",fontWeight:"bold"}}>Kaybetti</font>
        }
      }else if(bet_type == "3.5 Alt"){
        if(home_goal + away_goal < 3.5){
          return <font style={{color:"green",fontWeight:"bold"}}>Kazandı</font>
        }else{
          return <font style={{color:"red",fontWeight:"bold"}}>Kaybetti</font>
        }
      }else if(bet_type == "1.5 Üst"){
        if(home_goal + away_goal > 1.5){
          return <font style={{color:"green",fontWeight:"bold"}}>Kazandı</font>
        }else{
          return <font style={{color:"red",fontWeight:"bold"}}>Kaybetti</font>
        }
      }else if(bet_type == "1.5 Alt"){
        if(home_goal + away_goal < 1.5){
          return <font style={{color:"green",fontWeight:"bold"}}>Kazandı</font>
        }else{
          return <font style={{color:"red",fontWeight:"bold"}}>Kaybetti</font>
        }
      }else if(bet_type == "Karşılıklı Gol Var"){
        if(home_goal > 0 && away_goal > 0){
          return <font style={{color:"green",fontWeight:"bold"}}>Kazandı</font>
        }else{
          return <font style={{color:"red",fontWeight:"bold"}}>Kaybetti</font>
        }
      }else if(bet_type == "Karşılıklı Gol Yok"){
        if(home_goal > 0 && away_goal > 0){
          return <font style={{color:"red",fontWeight:"bold"}}>Kaybetti</font>
        }else{
          return <font style={{color:"green",fontWeight:"bold"}}>Kazandı</font>
        }
      }else if(bet_type == "MS 1"){
        if(home_goal > away_goal){
          return <font style={{color:"green",fontWeight:"bold"}}>Kazandı</font>
        }else{
          return <font style={{color:"red",fontWeight:"bold"}}>Kaybetti</font>
        }
      }
      return home_goal+" - "+away_goal
    }
  }

  const handleGroupTabClick = (groupTab) => {
    setActiveGroupTab(groupTab);
  };

  const handleMatchClick = (matchId) => {
    navigate(`/matches/${matchId}`); // Navigate to /matches/:matchId route
  };

  // Aktif sekmeye göre bahisleri filtreleme
  const filteredBets = bets.filter(bet => bet.bet_type === activeGroupTab);

  return (
    <div className="container mx-auto p-6">
      {isLoading && <Loading />}
      <h2 className="text-3xl font-bold mb-4">Değerli Bahisler</h2>
      <div className="flex justify-center mb-6">
        <div className="inline-flex rounded-md shadow-sm" role="group">
          <button
            type="button"
            onClick={() => handleGroupTabClick('Bankers')}
            className={`px-4 py-2 border border-gray-700 ${
              activeGroupTab === 'Bankers' ? 'bg-blue-500 text-white' : 'bg-gray-800 text-gray-400'
            }`}
          >
            Elite
          </button>
          <button
            type="button"
            onClick={() => handleGroupTabClick('Slip')}
            className={`px-4 py-2 border border-gray-700 ${
              activeGroupTab === 'Slip' ? 'bg-blue-500 text-white' : 'bg-gray-800 text-gray-400'
            }`}
          >
            Lite
          </button>
        </div>
      </div>

      {error ? (
        <div className="text-red-500">{error}</div>
      ) : bets.length > 0 ? (
        <div className="bg-gray-800 p-4 rounded overflow-x-auto">
          <table className="w-full text-left table-auto border-collapse border border-gray-700">
            <thead>
              <tr className="bg-gray-700 border-b border-gray-700">
                <th className="px-4 py-2 border border-gray-700">{t('match')}</th>
                <th className="px-4 py-2 border border-gray-700">{t('bet_description')}</th>
                {bets[0].trust ? (
                  <th className="px-4 py-2 border border-gray-700">Güvenirlik</th>
                ):(
                  <></>
                )}
                <th className="px-4 py-2 border border-gray-700">{t('match_date')}</th>
                <th className="px-4 py-2 border border-gray-700">Oran</th>
                <th>Sonuç</th>
              </tr>
            </thead>
            <tbody>
              {bets.map(bet => (
                <tr
                  key={bet.bet_id}
                  className="hover:bg-gray-700 border-b border-gray-700 cursor-pointer"
                  onClick={() => handleMatchClick(bet.match_id)}
                >
                  <td className="border px-4 py-2 border-gray-700">
                    {bet.home_team} vs {bet.away_team}
                  </td>
                  <td className="border px-4 py-2 border-gray-700">{bet.bet_description}</td>
                  {bet.trust ?  (
                    <td className="border px-4 py-2 border-gray-700">
                      {(bet.trust * 10).toFixed(1)}
                    </td>
                    ) : <></>}
                  <td className="border px-4 py-2 border-gray-700">
                    {new Date(bet.date_GMT).toLocaleDateString(i18n.language, { day: 'numeric', month: 'long', year: 'numeric',hour: 'numeric', minute: 'numeric' })}
                  </td>
                  <td className="border px-4 py-2 border-gray-700">{bet.odd}</td>
                  <td>
                    {calcStatus(bet.status, bet.bet_description, bet.home_team_goal_count, bet.away_team_goal_count)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Değerli Bahis mevcut değil.</p>
      )}
    </div>
  );
}

export default BetOfTheDay;
