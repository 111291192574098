// src/pages/SubscriptionUpgrade.jsx
import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config';
import { useTranslation } from 'react-i18next';

function SubscriptionUpgrade() {
  const { t } = useTranslation();
  const [transferCode, setTransferCode] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  // Kullanıcının transfer kodunu çekme
  useEffect(() => {
    const fetchTransferCode = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('Token bulunamadı');
          return;
        }

        const response = await fetch(`${API_BASE_URL}/profile/transfer-reference`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTransferCode(data.reference_code);
        } else {
          console.error('Transfer kodu alınamadı');
        }
      } catch (error) {
        console.error('Bir hata oluştu:', error);
      }
    };

    fetchTransferCode();
  }, []);

  // Transfer kodunu kopyalama
  const handleCopyCode = () => {
    navigator.clipboard.writeText(transferCode).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <div className="container mx-auto p-6 max-w-4xl bg-gray-800 rounded mt-10">
      <h2 className="text-3xl font-bold mb-6 text-white">{t('subscription_upgrade')}</h2>
      <p className="text-gray-300 mb-6">
        {t('upgrade_message')}
      </p>
      
      <p className="text-yellow-300 mb-6">
        {t('lemon_squeezy_notice')}
      </p>
      <p className="text-red-500 font-bold mb-6">
        {t('payment_notice')}
      </p>
      
      <div className="bg-gray-700 p-6 rounded shadow-md mb-6">
        <h3 className="text-2xl font-bold text-white mb-4">Ödeme için IBAN Numarası (İş Bankası)</h3>
        <p className="text-xl text-green-400 mb-4">TR18 0006 4000 0013 0291 1756 42</p>
        <p className="text-xl text-green-400 mb-4">Ertunç Okay Akça</p>
        <div className="bg-gray-800 p-4 rounded">
          <h4 className="text-lg font-bold text-white mb-2">{t('transfer_code_title')}</h4>
          <div className="flex items-center space-x-4">
            <span className="text-white">{transferCode}</span>
            <button
              onClick={handleCopyCode}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
            >
              {isCopied ? t('copied') : t('copy_code')}
            </button>
          </div>
        </div>
      </div>

      <p className="text-gray-300 mb-6">
        {t('payment_note')}
      </p>
    </div>
  );
}

export default SubscriptionUpgrade;
