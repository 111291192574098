import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Loading() {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative flex items-center justify-center">
        <div className="w-32 h-32 border-4 border-blue-400 border-solid rounded-full animate-spin-slow border-t-transparent absolute"></div>
        <div className="w-24 h-24 border-4 border-blue-500 border-solid rounded-full animate-spin-medium border-t-transparent absolute"></div>
      </div>
    </div>
  );
}

function ProgressBar({ leftValue, rightValue, label }) {
  const left = typeof leftValue === 'number' ? leftValue : parseFloat(leftValue) || 0;
  const right = typeof rightValue === 'number' ? rightValue : parseFloat(rightValue) || 0;
  const maxValue = Math.max(left, right, 1);
  const leftWidth = ((left / maxValue) * 100).toFixed(2);
  const rightWidth = ((right / maxValue) * 100).toFixed(2);

  return (
    <div className="mb-4">
      <p className="font-semibold mb-1">{label}</p>
      <div className="flex items-center">
        <div className="text-sm mr-2">{left.toFixed(2)}</div>
        <div className="flex-1 h-4 bg-gray-700 rounded flex">
          <div className="bg-blue-500 h-full" style={{ width: `${leftWidth}%` }}></div>
          <div className="bg-blue-300 h-full" style={{ width: `${rightWidth}%` }}></div>
        </div>
        <div className="text-sm ml-2">{right.toFixed(2)}</div>
      </div>
    </div>
  );
}

function MatchDetail() {
  const { t } = useTranslation();
  const { matchId } = useParams();
  const [matchDetail, setMatchDetail] = useState(null);
  const [predictions, setPredictions] = useState(null);
  const [h2hStats, setH2hStats] = useState(null);
  const [scoreProbabilities, setScoreProbabilities] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [similarMatches, setSimilarMatches] = useState(null);


  const fetchData = async (url, setter) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setter(data);
      } else {
        console.error('Veri alınamadı:', url);
      }
    } catch (error) {
      console.error('Bir hata oluştu:', error);
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      await Promise.all([
        fetchData(`https://api.smartbettor.app/api/matches/${matchId}/details`, setMatchDetail),
        fetchData(`https://api.smartbettor.app/api/matches/${matchId}/predictions`, setPredictions),
        fetchData(`https://api.smartbettor.app/api/matches/${matchId}/h2h`, setH2hStats),
        fetchData(`https://api.smartbettor.app/api/matches/${matchId}/score-probabilities`, setScoreProbabilities),
        fetchData(`https://api.smartbettor.app/api/matches/${matchId}/similar`, setSimilarMatches),
      ]);
      setIsLoading(false);
    };

    fetchAllData();
  }, [matchId]);

  if (isLoading) {
    return <Loading />;
  }

  if (!matchDetail) {
    return <p>{t('match_detail_not_found')}</p>;
  }

  return (
    <div className="container mx-auto p-4 sm:p-6">
      <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-center">{matchDetail.home_team} vs {matchDetail.away_team}</h2>
      <div className="bg-gray-800 p-4 rounded">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="bg-gray-700 p-4 rounded">
            <h4 className="text-lg sm:text-xl font-semibold mb-4">{t('match_info')}</h4>
            <table className="min-w-full divide-y divide-gray-600 text-sm sm:text-base">
              <tbody className="bg-gray-800 divide-y divide-gray-700">
                <tr>
                  <td className="px-2 sm:px-4 py-2 text-gray-300">{t('match_date')}</td>
                  <td className="px-2 sm:px-4 py-2">{new Date(matchDetail.match_date).toLocaleDateString('tr-TR')}</td>
                </tr>
                <tr>
                  <td className="px-2 sm:px-4 py-2 text-gray-300">{t('status')}</td>
                  <td className="px-2 sm:px-4 py-2">{matchDetail.status}</td>
                </tr>
                <tr>
                  <td className="px-2 sm:px-4 py-2 text-gray-300">{t('attendance')}</td>
                  <td className="px-2 sm:px-4 py-2">{matchDetail.attendance || 'Bilinmiyor'}</td>
                </tr>
                <tr>
                  <td className="px-2 sm:px-4 py-2 text-gray-300">{t('referee')}</td>
                  <td className="px-2 sm:px-4 py-2">{matchDetail.referee || 'Bilinmiyor'}</td>
                </tr>
                <tr>
                  <td className="px-2 sm:px-4 py-2 text-gray-300">{t('game_week')}</td>
                  <td className="px-2 sm:px-4 py-2">{matchDetail.game_week}</td>
                </tr>
                <tr>
                  <td className="px-2 sm:px-4 py-2 text-gray-300">{t('stadium')}</td>
                  <td className="px-2 sm:px-4 py-2">{matchDetail.stadium_name}</td>
                </tr>
                <tr>
                  <td className="px-2 sm:px-4 py-2 text-gray-300">{t('league')}</td>
                  <td className="px-2 sm:px-4 py-2">{matchDetail.league_name}</td>
                </tr>
                <tr>
                  <td className="px-2 sm:px-4 py-2 text-gray-300">{t('season')}</td>
                  <td className="px-2 sm:px-4 py-2">{matchDetail.season}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="bg-gray-700 p-4 rounded">
            <h4 className="text-lg sm:text-xl font-semibold mb-4">{t('predicted')}</h4>
            {predictions ? (
              <table className="min-w-full divide-y divide-gray-600 text-sm sm:text-base">
                <thead>
                  <tr className="bg-gray-700">
                    <th className="px-2 sm:px-4 py-2 text-left text-gray-300">{t('prediction')}</th>
                    <th className="px-2 sm:px-4 py-2 text-left text-gray-300">{t('probability')}</th>
                    <th className="px-2 sm:px-4 py-2 text-left text-gray-300">{t('trust')}</th>
                  </tr>
                </thead>
                <tbody className="bg-gray-800 divide-y divide-gray-700">
                  <tr>
                    <td className="px-2 sm:px-4 py-2">{t('over_under_1_5')}</td>
                    <td className="px-2 sm:px-4 py-2">{predictions.predicted_over_under_1_5 === 0 ? '1.5:Alt' : '1.5:Üst'} ({(predictions.predicted_1_5_prob * 100).toFixed(0)}%)</td>
                    <td className="px-2 sm:px-4 py-2">{predictions.trust_1_5 === 0 ? '-' : (predictions.trust_1_5 * 10).toFixed(1)}</td>
                  </tr>
                  <tr>
                    <td className="px-2 sm:px-4 py-2">{t('over_under_2_5')}</td>
                    <td className="px-2 sm:px-4 py-2">{predictions.predicted_over_under_2_5 === 0 ? '2.5:Alt' : '2.5:Üst'} ({(predictions.predicted_2_5_prob * 100).toFixed(0)}%)</td>
                    <td className="px-2 sm:px-4 py-2">{predictions.trust_2_5 === 0 ? '-' : (predictions.trust_2_5 * 10).toFixed(1)}</td>
                  </tr>
                  <tr>
                    <td className="px-2 sm:px-4 py-2">{t('over_under_3_5')}</td>
                    <td className="px-2 sm:px-4 py-2">{predictions.predicted_over_under_3_5 === 0 ? '3.5:Alt' : '3.5:Üst'} ({(predictions.predicted_3_5_prob * 100).toFixed(0)}%)</td>
                    <td className="px-2 sm:px-4 py-2">{predictions.trust_3_5 === 0 ? '-' : (predictions.trust_3_5 * 10).toFixed(1)}</td>
                  </tr>
                  <tr>
                    <td className="px-2 sm:px-4 py-2">{t('both_teams_to_score')}</td>
                    <td className="px-2 sm:px-4 py-2">{predictions.predicted_btts ? 'Var' : 'Yok'} ({(predictions.predicted_btts_prob * 100).toFixed(0)}%)</td>
                    <td className="px-2 sm:px-4 py-2">{predictions.trust_both_teams === 0 ? '-' : (predictions.trust_both_teams * 10).toFixed(1)}</td>
                  </tr>
                  <tr>
                    <td className="px-2 sm:px-4 py-2">{t('home_win')}</td>
                    <td className="px-2 sm:px-4 py-2">{(predictions.predicted_home_win_prob * 100).toFixed(0)}%</td>
                    <td className="px-2 sm:px-4 py-2">{predictions.trust_home_win === 0 ? '-' : (predictions.trust_home_win * 10).toFixed(1)}</td>
                  </tr>
                  <tr>
                    <td className="px-2 sm:px-4 py-2">{t('draw')}</td>
                    <td className="px-2 sm:px-4 py-2">{(predictions.predicted_draw_prob * 100).toFixed(0)}%</td>
                    <td className="px-2 sm:px-4 py-2">{predictions.trust_draw === 0 ? '-' : (predictions.trust_draw * 10).toFixed(1)}</td>
                  </tr>
                  <tr>
                    <td className="px-2 sm:px-4 py-2">{t('away_win')}</td>
                    <td className="px-2 sm:px-4 py-2">{(predictions.predicted_away_win_prob * 100).toFixed(0)}%</td>
                    <td className="px-2 sm:px-4 py-2">{predictions.trust_away_win === 0 ? '-' : (predictions.trust_away_win * 10).toFixed(1)}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <p>{t('no_predictions')}</p>
            )}
          </div>
          <div className="bg-gray-700 p-4 rounded">
            <h4 className="text-lg sm:text-xl font-semibold mb-4">İlk Yarı / Maç Sonucu</h4>
            {predictions.iy0_ms0 ? (
              <>
                <table className="min-w-full divide-y divide-gray-600 text-sm sm:text-base">
                  <thead>
                    <tr className="bg-gray-700">
                      <th className="px-2 sm:px-4 py-2 text-left text-gray-300">{t('prediction')}</th>
                      <th className="px-2 sm:px-4 py-2 text-left text-gray-300">{t('probability')}</th>
                      <th className="px-2 sm:px-4 py-2 text-left text-gray-300">{t('trust')}</th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-800 divide-y divide-gray-700">
                    <tr>
                      <td className="px-2 sm:px-4 py-2">0/0</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy0_ms0 * 100).toFixed(0)}%</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy0_ms0_trust * 10).toFixed(1)}</td>
                    </tr>
                    <tr>
                      <td className="px-2 sm:px-4 py-2">0/1</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy0_ms1 * 100).toFixed(0)}%</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy0_ms1_trust * 10).toFixed(1)}</td>
                    </tr>
                    <tr>
                      <td className="px-2 sm:px-4 py-2">0/2</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy0_ms2 * 100).toFixed(0)}%</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy0_ms2_trust * 10).toFixed(1)}</td>
                    </tr>
                    <tr>
                      <td className="px-2 sm:px-4 py-2">1/0</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy1_ms0 * 100).toFixed(0)}%</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy1_ms0_trust * 10).toFixed(1)}</td>
                    </tr>
                    <tr>
                      <td className="px-2 sm:px-4 py-2">1/1</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy1_ms1 * 100).toFixed(0)}%</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy1_ms1_trust * 10).toFixed(1)}</td>
                    </tr>
                    <tr>
                      <td className="px-2 sm:px-4 py-2">1/2</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy1_ms2 * 100).toFixed(0)}%</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy1_ms2_trust * 10).toFixed(1)}</td>
                    </tr>
                    <tr>
                      <td className="px-2 sm:px-4 py-2">2/0</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy2_ms0 * 100).toFixed(0)}%</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy2_ms0_trust * 10).toFixed(1)}</td>
                    </tr>
                    <tr>
                      <td className="px-2 sm:px-4 py-2">2/2</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy2_ms2 * 100).toFixed(0)}%</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy2_ms2_trust * 10).toFixed(1)}</td>
                    </tr>
                    <tr>
                      <td className="px-2 sm:px-4 py-2">2/1</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy2_ms1 * 100).toFixed(0)}%</td>
                      <td className="px-2 sm:px-4 py-2">{(predictions.iy2_ms1_trust * 10).toFixed(1)}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <p>İlk Yarı / Maç Sonucu istatistikleri mevcut değil.</p>
            )}
          </div>
          <div className="bg-gray-700 p-4 rounded">
            <h4 className="text-lg sm:text-xl font-semibold mb-4">{t('top_10_score_probabilities')}</h4>
            {scoreProbabilities && scoreProbabilities.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-600 text-sm sm:text-base">
                  <thead>
                    <tr className="bg-gray-700">
                      <th className="px-2 sm:px-4 py-2 text-left text-gray-300">{t('predicted_score')}</th>
                      <th className="px-2 sm:px-4 py-2 text-left text-gray-300">{t('probability')}</th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-800 divide-y divide-gray-700">
                    {scoreProbabilities.map((probability, index) => (
                      <tr key={index}>
                        <td className="px-2 sm:px-4 py-2">{probability.home_goals} - {probability.away_goals}</td>
                        <td className="px-2 sm:px-4 py-2">{(probability.probability * 100).toFixed(0)}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>{t('no_score_probabilities')}</p>
            )}
          </div>
          <div className="bg-gray-700 p-4 rounded">
            <h4 className="text-lg sm:text-xl font-semibold mb-4">{t('h2h_stats')}</h4>
            {h2hStats && h2hStats.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-600 text-sm sm:text-base">
                  <thead>
                    <tr className="bg-gray-700">
                      <th className="px-2 sm:px-4 py-2 text-left text-gray-300">{t('date')}</th>
                      <th className="px-2 sm:px-4 py-2 text-left text-gray-300">{t('home_team')}</th>
                      <th className="px-2 sm:px-4 py-2 text-left text-gray-300">{t('score')}</th>
                      <th className="px-2 sm:px-4 py-2 text-left text-gray-300">{t('away_team')}</th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-800 divide-y divide-gray-700">
                    {h2hStats.map((match, index) => (
                      <tr key={index}>
                        <td className="px-2 sm:px-4 py-2">{new Date(match.date_GMT).toLocaleDateString('tr-TR')}</td>
                        <td className="px-2 sm:px-4 py-2">{match.home_team}</td>
                        <td className="px-2 sm:px-4 py-2">{match.home_team_goal_count} - {match.away_team_goal_count}</td>
                        <td className="px-2 sm:px-4 py-2">{match.away_team}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>{t('no_h2h_stats')}</p>
            )}
          </div>
          {/* Benzer Maçlar */}
          <div className="bg-gray-700 p-4 rounded">
            <h4 className="text-lg sm:text-xl font-semibold mb-4">{t('similar_matches')}</h4>
            {similarMatches && similarMatches.similar_matches.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-600 text-sm sm:text-base">
                  <thead>
                    <tr className="bg-gray-700">
                      <th className="px-2 sm:px-4 py-2 text-left text-gray-300">{t('match')}</th>
                      <th className="px-2 sm:px-4 py-2 text-left text-gray-300">İY</th>
                      <th className="px-2 sm:px-4 py-2 text-left text-gray-300">MS</th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-800 divide-y divide-gray-700">
                    {similarMatches.similar_matches.map((match, index) => (
                      <tr key={index}>
                        <td className="px-2 sm:px-4 py-2">{match.home_team+" - "+match.away_team}</td>
                        <td className="px-2 sm:px-4 py-2">{match.home_team_goal_count_1st} - {match.away_team_goal_count_1st}</td>
                        <td className="px-2 sm:px-4 py-2">{match.home_team_goal_count} - {match.away_team_goal_count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>{t('no_similar_matches')}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MatchDetail;
