// src/data/countries.js

const countries = [
    { name: 'Afganistan', value: 'afghanistan' },
    { name: 'Almanya', value: 'germany' },
    { name: 'Amerika Birleşik Devletleri', value: 'usa' },
    { name: 'Andorra', value: 'andorra' },
    { name: 'Angola', value: 'angola' },
    { name: 'Antigua ve Barbuda', value: 'antigua_and_barbuda' },
    { name: 'Arjantin', value: 'argentina' },
    { name: 'Arnavutluk', value: 'albania' },
    { name: 'Avustralya', value: 'australia' },
    { name: 'Avusturya', value: 'austria' },
    { name: 'Azerbaycan', value: 'azerbaijan' },
    { name: 'Bahama Adaları', value: 'bahamas' },
    { name: 'Bahreyn', value: 'bahrain' },
    { name: 'Bangladeş', value: 'bangladesh' },
    { name: 'Barbados', value: 'barbados' },
    { name: 'Belçika', value: 'belgium' },
    { name: 'Belize', value: 'belize' },
    { name: 'Benin', value: 'benin' },
    { name: 'Beyaz Rusya', value: 'belarus' },
    { name: 'Birleşik Arap Emirlikleri', value: 'uae' },
    { name: 'Bolivya', value: 'bolivia' },
    { name: 'Bosna-Hersek', value: 'bosnia_and_herzegovina' },
    { name: 'Botsvana', value: 'botswana' },
    { name: 'Brezilya', value: 'brazil' },
    { name: 'Brunei', value: 'brunei' },
    { name: 'Bulgaristan', value: 'bulgaria' },
    { name: 'Burkina Faso', value: 'burkina_faso' },
    { name: 'Burundi', value: 'burundi' },
    { name: 'Cezayir', value: 'algeria' },
    { name: 'Cibuti', value: 'djibouti' },
    { name: 'Çad', value: 'chad' },
    { name: 'Çek Cumhuriyeti', value: 'czech_republic' },
    { name: 'Çin', value: 'china' },
    { name: 'Danimarka', value: 'denmark' },
    { name: 'Dominik Cumhuriyeti', value: 'dominican_republic' },
    { name: 'Dominika', value: 'dominica' },
    { name: 'Ekvador', value: 'ecuador' },
    { name: 'Ekvator Ginesi', value: 'equatorial_guinea' },
    { name: 'El Salvador', value: 'el_salvador' },
    { name: 'Endonezya', value: 'indonesia' },
    { name: 'Eritre', value: 'eritrea' },
    { name: 'Ermenistan', value: 'armenia' },
    { name: 'Estonya', value: 'estonia' },
    { name: 'Etiyopya', value: 'ethiopia' },
    { name: 'Fas', value: 'morocco' },
    { name: 'Fiji', value: 'fiji' },
    { name: 'Filipinler', value: 'philippines' },
    { name: 'Filistin', value: 'palestine' },
    { name: 'Finlandiya', value: 'finland' },
    { name: 'Fransa', value: 'france' },
    { name: 'Gabon', value: 'gabon' },
    { name: 'Gambiya', value: 'gambia' },
    { name: 'Gana', value: 'ghana' },
    { name: 'Gine', value: 'guinea' },
    { name: 'Gine-Bissau', value: 'guinea_bissau' },
    { name: 'Grenada', value: 'grenada' },
    { name: 'Guatemala', value: 'guatemala' },
    { name: 'Guyana', value: 'guyana' },
    { name: 'Güney Afrika', value: 'south_africa' },
    { name: 'Güney Kore', value: 'south_korea' },
    { name: 'Güney Sudan', value: 'south_sudan' },
    { name: 'Haiti', value: 'haiti' },
    { name: 'Hırvatistan', value: 'croatia' },
    { name: 'Hindistan', value: 'india' },
    { name: 'Hollanda', value: 'netherlands' },
    { name: 'Honduras', value: 'honduras' },
    { name: 'Irak', value: 'iraq' },
    { name: 'İngiltere', value: 'uk' },
    { name: 'İran', value: 'iran' },
    { name: 'İrlanda', value: 'ireland' },
    { name: 'İspanya', value: 'spain' },
    { name: 'İsrail', value: 'israel' },
    { name: 'İsveç', value: 'sweden' },
    { name: 'İsviçre', value: 'switzerland' },
    { name: 'İtalya', value: 'italy' },
    { name: 'İzlanda', value: 'iceland' },
    { name: 'Jamaika', value: 'jamaica' },
    { name: 'Japonya', value: 'japan' },
    { name: 'Kamboçya', value: 'cambodia' },
    { name: 'Kamerun', value: 'cameroon' },
    { name: 'Kanada', value: 'canada' },
    { name: 'Katar', value: 'qatar' },
    { name: 'Kazakistan', value: 'kazakhstan' },
    { name: 'Kenya', value: 'kenya' },
    { name: 'Kırgızistan', value: 'kyrgyzstan' },
    { name: 'Kiribati', value: 'kiribati' },
    { name: 'Kolombiya', value: 'colombia' },
    { name: 'Komor Adaları', value: 'comoros' },
    { name: 'Kongo Cumhuriyeti', value: 'congo_republic' },
    { name: 'Kongo Demokratik Cumhuriyeti', value: 'congo_democratic_republic' },
    { name: 'Kosta Rika', value: 'costa_rica' },
    { name: 'Kuveyt', value: 'kuwait' },
    { name: 'Kuzey Kore', value: 'north_korea' },
    { name: 'Kuzey Makedonya', value: 'north_macedonia' },
    { name: 'Küba', value: 'cuba' },
    { name: 'Laos', value: 'laos' },
    { name: 'Letonya', value: 'latvia' },
    { name: 'Liberya', value: 'liberia' },
    { name: 'Libya', value: 'libya' },
    { name: 'Liechtenstein', value: 'liechtenstein' },
    { name: 'Litvanya', value: 'lithuania' },
    { name: 'Lübnan', value: 'lebanon' },
    { name: 'Lüksemburg', value: 'luxembourg' },
    { name: 'Macaristan', value: 'hungary' },
    { name: 'Madagaskar', value: 'madagascar' },
    { name: 'Malavi', value: 'malawi' },
    { name: 'Maldivler', value: 'maldives' },
    { name: 'Malezya', value: 'malaysia' },
    { name: 'Mali', value: 'mali' },
    { name: 'Malta', value: 'malta' },
    { name: 'Marshall Adaları', value: 'marshall_islands' },
    { name: 'Meksika', value: 'mexico' },
    { name: 'Mısır', value: 'egypt' },
    { name: 'Mikronezya', value: 'micronesia' },
    { name: 'Moğolistan', value: 'mongolia' },
    { name: 'Moldova', value: 'moldova' },
    { name: 'Monako', value: 'monaco' },
    { name: 'Moritanya', value: 'mauritania' },
    { name: 'Morityus', value: 'mauritius' },
    { name: 'Mozambik', value: 'mozambique' },
    { name: 'Myanmar', value: 'myanmar' },
    { name: 'Namibya', value: 'namibia' },
    { name: 'Nauru', value: 'nauru' },
    { name: 'Nepal', value: 'nepal' },
    { name: 'Nijer', value: 'niger' },
    { name: 'Nijerya', value: 'nigeria' },
    { name: 'Nikaragua', value: 'nicaragua' },
    { name: 'Norveç', value: 'norway' },
    { name: 'Orta Afrika Cumhuriyeti', value: 'central_african_republic' },
    { name: 'Özbekistan', value: 'uzbekistan' },
    { name: 'Pakistan', value: 'pakistan' },
    { name: 'Palau', value: 'palau' },
    { name: 'Panama', value: 'panama' },
    { name: 'Papua Yeni Gine', value: 'papua_new_guinea' },
    { name: 'Paraguay', value: 'paraguay' },
    { name: 'Peru', value: 'peru' },
    { name: 'Polonya', value: 'poland' },
    { name: 'Portekiz', value: 'portugal' },
    { name: 'Romanya', value: 'romania' },
    { name: 'Ruanda', value: 'rwanda' },
    { name: 'Rusya', value: 'russia' },
    { name: 'Saint Kitts ve Nevis', value: 'saint_kitts_and_nevis' },
    { name: 'Saint Lucia', value: 'saint_lucia' },
    { name: 'Saint Vincent ve Grenadinler', value: 'saint_vincent_and_the_grenadines' },
    { name: 'Samoa', value: 'samoa' },
    { name: 'San Marino', value: 'san_marino' },
    { name: 'Sao Tome ve Principe', value: 'sao_tome_and_principe' },
    { name: 'Senegal', value: 'senegal' },
    { name: 'Seyşeller', value: 'seychelles' },
    { name: 'Sierra Leone', value: 'sierra_leone' },
    { name: 'Singapur', value: 'singapore' },
    { name: 'Slovakya', value: 'slovakia' },
    { name: 'Slovenya', value: 'slovenia' },
    { name: 'Solomon Adaları', value: 'solomon_islands' },
    { name: 'Somali', value: 'somalia' },
    { name: 'Sri Lanka', value: 'sri_lanka' },
    { name: 'Sudan', value: 'sudan' },
    { name: 'Surinam', value: 'suriname' },
    { name: 'Suriye', value: 'syria' },
    { name: 'Suudi Arabistan', value: 'saudi_arabia' },
    { name: 'Svaziland', value: 'eswatini' },
    { name: 'Şili', value: 'chile' },
    { name: 'Tacikistan', value: 'tajikistan' },
    { name: 'Tanzanya', value: 'tanzania' },
    { name: 'Tayland', value: 'thailand' },
    { name: 'Tayvan', value: 'taiwan' },
    { name: 'Togo', value: 'togo' },
    { name: 'Tonga', value: 'tonga' },
    { name: 'Trinidad ve Tobago', value: 'trinidad_and_tobago' },
    { name: 'Tunus', value: 'tunisia' },
    { name: 'Türkiye', value: 'turkey' },
    { name: 'Türkmenistan', value: 'turkmenistan' },
    { name: 'Tuvalu', value: 'tuvalu' },
    { name: 'Uganda', value: 'uganda' },
    { name: 'Ukrayna', value: 'ukraine' },
    { name: 'Umman', value: 'oman' },
    { name: 'Uruguay', value: 'uruguay' },
    { name: 'Ürdün', value: 'jordan' },
    { name: 'Vanuatu', value: 'vanuatu' },
    { name: 'Vatikan', value: 'vatican' },
    { name: 'Venezuela', value: 'venezuela' },
    { name: 'Vietnam', value: 'vietnam' },
    { name: 'Yemen', value: 'yemen' },
    { name: 'Yeni Zelanda', value: 'new_zealand' },
    { name: 'Yunanistan', value: 'greece' },
    { name: 'Zambia', value: 'zambia' },
    { name: 'Zimbabve', value: 'zimbabwe' },
  ];
  
  export default countries;
  