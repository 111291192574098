import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  const [showCookieBanner, setShowCookieBanner] = useState(!localStorage.getItem('cookiesAccepted'));

  const handleAcceptCookies = () => {
    setShowCookieBanner(false);
    localStorage.setItem('cookiesAccepted', 'true');
  };

  return (
    <>
      <footer className="bg-gray-800 p-4 text-center text-white">
        <Link to="/terms-of-service" className="mr-4 hover:underline">{t('terms_of_service')}</Link>
        <Link to="/privacy-policy" className="mr-4 hover:underline">{t('privacy_policy')}</Link>
        <Link to="/cookie-policy" className="hover:underline">{t('cookie_policy')}</Link>
      </footer>

      {showCookieBanner && (
        <div className="fixed bottom-0 left-0 right-0 bg-gray-700 p-4 text-white text-center shadow-md">
          <p>
            {t('cookie_message')}{' '}
            <Link to="/cookie-policy" className="underline">{t('cookie_policy')}</Link>.
          </p>
          <button
            onClick={handleAcceptCookies}
            className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
          >
            {t('accept')}
          </button>
        </div>
      )}
    </>
  );
}

export default Footer;
