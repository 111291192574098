// src/pages/TermsOfService.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';

function TermsOfService() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto p-6 max-w-4xl bg-gray-900 text-white rounded mt-10">
      <h2 className="text-3xl font-bold mb-6">{t('terms_of_service_title')}</h2>
      <p className="mb-4">{t('terms_of_service_intro')}</p>
      <div className="bg-gray-800 p-4 rounded space-y-4">
        <h3 className="text-2xl font-semibold mb-2">{t('terms_of_service_content.last_updated')}</h3>
        <p>{t('terms_of_service_content.welcome')}</p>
        <p>{t('terms_of_service_content.accept_terms')}</p>

        <h4 className="text-xl font-bold">{t('terms_of_service_content.definitions_title')}</h4>
        <ul className="list-disc list-inside">
          {t('terms_of_service_content.definitions', { returnObjects: true }).map((definition, index) => (
            <li key={index}>{definition}</li>
          ))}
        </ul>

        <h4 className="text-xl font-bold">{t('terms_of_service_content.user_responsibilities_title')}</h4>
        <p>{t('terms_of_service_content.user_responsibilities')}</p>

        <h4 className="text-xl font-bold">{t('terms_of_service_content.subscription_title')}</h4>
        <p>{t('terms_of_service_content.subscription')}</p>

        <h4 className="text-xl font-bold">{t('terms_of_service_content.prohibited_uses_title')}</h4>
        <p>{t('terms_of_service_content.prohibited_uses')}</p>

        <h4 className="text-xl font-bold">{t('terms_of_service_content.termination_title')}</h4>
        <p>{t('terms_of_service_content.termination')}</p>

        <h4 className="text-xl font-bold">{t('terms_of_service_content.changes_title')}</h4>
        <p>{t('terms_of_service_content.changes')}</p>

        <h4 className="text-xl font-bold">{t('terms_of_service_content.contact_title')}</h4>
        <p>{t('terms_of_service_content.contact')}</p>
      </div>
    </div>
  );
}

export default TermsOfService;
