// src/pages/Profile.jsx

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import { useTranslation } from 'react-i18next';

function Profile() {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(null);
  const [activeSection, setActiveSection] = useState('account');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordChangeMessage, setPasswordChangeMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('Token bulunamadı');
          return;
        }

        const response = await fetch(`${API_BASE_URL}/profile`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data);
          setUpdatedUser(data);
        } else {
          console.error('Kullanıcı bilgileri alınamadı');
        }
      } catch (error) {
        console.error('Bir hata oluştu:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleEdit = () => setEditMode(true);

  const handleCancel = () => {
    setEditMode(false);
    setUpdatedUser(user);
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      
      const response = await fetch(`${API_BASE_URL}/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updatedUser)
      });

      if (response.ok) {
        setUser(updatedUser);
        setEditMode(false);
        alert(t('edit_profile_success'));
      } else {
        alert(t('edit_profile_fail'));
      }
    } catch (error) {
      console.error('Bilgi güncelleme hatası:', error);
      alert(t('edit_profile_fail'));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser((prev) => ({ ...prev, [name]: value }));
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setPasswordChangeMessage(t('password_change_message'));
      return;
    }

    try {
      const token = localStorage.getItem('token');
      
      const response = await fetch(`${API_BASE_URL}/profile/change-password`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          currentPassword,
          newPassword
        })
      });

      if (response.ok) {
        setPasswordChangeMessage(t('password_change_success'));
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      } else {
        const errorData = await response.json();
        setPasswordChangeMessage(errorData.error || t('password_change_fail'));
      }
    } catch (error) {
      console.error('Şifre değiştirme hatası:', error);
      setPasswordChangeMessage(t('password_change_fail'));
    }
  };

  const renderSection = () => {
    if (!user) {
      return <p className="text-white">{t('loading')}</p>;
    }

    switch (activeSection) {
      case 'account':
        return (
          <div>
            {editMode ? (
              <form className="space-y-4">
                <div>
                  <label className="block text-white mb-2" htmlFor="firstName">
                    {t('first_name')}
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="first_name"
                    value={updatedUser.first_name || ''}
                    onChange={handleChange}
                    className="w-full p-2 rounded bg-gray-700 text-white"
                  />
                </div>
                <div>
                  <label className="block text-white mb-2" htmlFor="lastName">
                    {t('last_name')}
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="last_name"
                    value={updatedUser.last_name || ''}
                    onChange={handleChange}
                    className="w-full p-2 rounded bg-gray-700 text-white"
                  />
                </div>
                <div>
                  <label className="block text-white mb-2" htmlFor="email">
                    {t('email')}
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={updatedUser.email || ''}
                    onChange={handleChange}
                    className="w-full p-2 rounded bg-gray-700 text-white"
                  />
                </div>
                <div>
                  <label className="block text-white mb-2" htmlFor="country">
                    {t('country')}
                  </label>
                  <input
                    type="text"
                    id="country"
                    name="country"
                    value={updatedUser.country || ''}
                    onChange={handleChange}
                    className="w-full p-2 rounded bg-gray-700 text-white"
                  />
                </div>
                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleCancel}
                  >
                    {t('cancel')}
                  </button>
                  <button
                    type="button"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleSave}
                  >
                    {t('save')}
                  </button>
                </div>
              </form>
            ) : (
              <div className="space-y-4">
                <p className="text-white">
                  <strong>{t('first_name')}:</strong> {user.first_name}
                </p>
                <p className="text-white">
                  <strong>{t('last_name')}:</strong> {user.last_name}
                </p>
                <p className="text-white">
                  <strong>{t('email')}:</strong> {user.email}
                </p>
                <p className="text-white">
                  <strong>{t('country')}:</strong> {user.country}
                </p>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleEdit}
                >
                  {t('edit')}
                </button>
              </div>
            )}
          </div>
        );
      case 'subscription':
        const currentDate = new Date();
        const subscriptionEndDate = new Date(user.subscription_end_date);
        const daysLeft = Math.ceil((subscriptionEndDate - currentDate) / (1000 * 60 * 60 * 24));
        const isExpired = daysLeft <= 0;
        const subscriptionType = isExpired ? t('subscription_free') : user.subscription_type;
        const subscriptionStatus = subscriptionType === t('subscription_free') ? '∞' : (daysLeft > 0 ? t('subscription_status_active', { daysLeft }) : t('subscription_status_expired'));

        return (
          <div>
            <h3 className="text-2xl font-bold mb-4 text-white">{t('subscription_type')}: {subscriptionType}</h3>
            <p className="text-white mb-4">
              <strong>{t('subscription_end_date')}:</strong> {subscriptionType === t('subscription_free') ? '∞' : user.subscription_end_date} ({subscriptionStatus})
            </p>
            
            {/* Yönlendirme bağlantısı eklendi */}
            

            {(subscriptionType === t('subscription_free') || subscriptionType === t('subscription_pro')) && (
              <Link
                to="/subscription-upgrade"
                className="text-blue-500 hover:underline font-bold mb-4 block"
              >
                {t('subscription_upgrade')}
              </Link>
            )}
            <div className="space-y-4">
              <div className="bg-gray-700 p-4 rounded">
                <h4 className="text-xl font-bold text-white">{t('subscription_free')}</h4>
                <ul className="list-disc list-inside text-gray-300">
                  <li>{t('calculate_bet_investment')}</li>
                  <li>{t('past_match_info')}</li>
                </ul>
              </div>
              <div className="bg-gray-700 p-4 rounded">
                <h4 className="text-xl font-bold text-white">{t('subscription_pro')}</h4>
                <ul className="list-disc list-inside text-gray-300">
                  <li>{t('calculate_bet_investment')}</li>
                  <li>{t('past_match_info')}</li>
                  <li>{t('predictions_probabilities')}</li>
                </ul>
              </div>
              <div className="bg-gray-700 p-4 rounded">
                <h4 className="text-xl font-bold text-white">{t('subscription_elite')} ({t('limited_stock')})</h4>
                <ul className="list-disc list-inside text-gray-300">
                  <li>{t('calculate_bet_investment')}</li>
                  <li>{t('past_match_info')}</li>
                  <li>{t('predictions_probabilities')}</li>
                  <li>{t('reliability_values')}</li>
                  <li>{t('match_recommendations')}</li>
                </ul>
              </div>
            </div>
          </div>
        );
      case 'changePassword':
        return (
          <form onSubmit={handlePasswordChange} className="space-y-4">
            <div>
              <label className="block text-white mb-2" htmlFor="currentPassword">
                {t('current_password')}
              </label>
              <input
                type="password"
                id="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="w-full p-2 rounded bg-gray-700 text-white"
                required
              />
            </div>
            <div>
              <label className="block text-white mb-2" htmlFor="newPassword">
                {t('new_password')}
              </label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full p-2 rounded bg-gray-700 text-white"
                required
              />
            </div>
            <div>
              <label className="block text-white mb-2" htmlFor="confirmNewPassword">
                {t('confirm_new_password')}
              </label>
              <input
                type="password"
                id="confirmNewPassword"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                className="w-full p-2 rounded bg-gray-700 text-white"
                required
              />
            </div>
            {passwordChangeMessage && (
              <p className="text-red-500">{passwordChangeMessage}</p>
            )}
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {t('change_password')}
            </button>
          </form>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto p-6 max-w-4xl bg-gray-800 rounded mt-10 flex">
      <div className="w-1/4 bg-gray-900 p-4 rounded-l">
        <ul className="space-y-4">
          <li>
            <button
              className={`w-full text-left p-2 rounded ${
                activeSection === 'account' ? 'bg-gray-700 text-white' : 'text-gray-400'
              } hover:bg-gray-700`}
              onClick={() => setActiveSection('account')}
            >
              {t('account_info')}
            </button>
          </li>
          <li>
            <button
              className={`w-full text-left p-2 rounded ${
                activeSection === 'subscription' ? 'bg-gray-700 text-white' : 'text-gray-400'
              } hover:bg-gray-700`}
              onClick={() => setActiveSection('subscription')}
            >
              {t('subscription')}
            </button>
          </li>
          <li>
            <button
              className={`w-full text-left p-2 rounded ${
                activeSection === 'changePassword' ? 'bg-gray-700 text-white' : 'text-gray-400'
              } hover:bg-gray-700`}
              onClick={() => setActiveSection('changePassword')}
            >
              {t('change_password')}
            </button>
          </li>
        </ul>
      </div>
      <div className="w-3/4 bg-gray-800 p-6 rounded-r">
        <h2 className="text-3xl font-bold mb-6 text-white">{t('profile')}</h2>
        {renderSection()}
      </div>
    </div>
  );
}

export default Profile;
