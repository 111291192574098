// src/pages/Register.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import countries from '../data/countries';
import { useTranslation } from 'react-i18next';

function Register() {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [country, setCountry] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert(t('passwords_do_not_match'));
      return;
    }
    if (!termsAccepted) {
      alert(t('terms_not_accepted'));
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: email,
          password: password,
          first_name: firstName,
          last_name: lastName,
          country: country,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        alert(t('registration_success'));
        navigate('/login');
      } else {
        alert(data.error || t('registration_failed'));
      }
    } catch (error) {
      console.error(t('registration_error'), error);
      alert(t('registration_error'));
    }
  };

  return (
    <div className="container mx-auto p-6 max-w-lg">
      <h2 className="text-3xl font-bold mb-4">{t('register')}</h2>
      <form onSubmit={handleRegister} className="bg-gray-800 p-4 rounded">
        <div className="mb-4 flex space-x-4">
          <div className="w-1/2">
            <label className="block text-white mb-2" htmlFor="firstName">{t('first_name')}</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="w-full p-2 rounded bg-gray-700 text-white"
              required
            />
          </div>
          <div className="w-1/2">
            <label className="block text-white mb-2" htmlFor="lastName">{t('last_name')}</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="w-full p-2 rounded bg-gray-700 text-white"
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-white mb-2" htmlFor="email">{t('email')}</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-white"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-white mb-2" htmlFor="country">{t('country')}</label>
          <select
            id="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-white"
            required
          >
            <option value="">{t('select_country')}</option>
            {countries.map((country, index) => (
              <option key={index} value={country.value}>{country.name}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-white mb-2" htmlFor="password">{t('password')}</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-white"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-white mb-2" htmlFor="confirmPassword">{t('confirm_password')}</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-white"
            required
          />
        </div>
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              className="form-checkbox text-blue-500 bg-gray-700 rounded"
              required
            />
            <span className="ml-2 text-white">{t('terms_acceptance')}</span>
          </label>
        </div>
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          {t('register_button')}
        </button>
      </form>
    </div>
  );
}

export default Register;
