import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng); // Save the selected language to localStorage
  };

  return (
    <div className="text-right p-4">
      <button onClick={() => changeLanguage('en')} className="px-2 text-white hover:underline">
        EN
      </button>
      {/* Uncomment if additional languages are supported */}
      {/* <button onClick={() => changeLanguage('de')} className="px-2 text-white hover:underline">
        DE
      </button>
      <button onClick={() => changeLanguage('es')} className="px-2 text-white hover:underline">
        ES
      </button>
      <button onClick={() => changeLanguage('it')} className="px-2 text-white hover:underline">
        IT
      </button>
      <button onClick={() => changeLanguage('pt')} className="px-2 text-white hover:underline">
        PT
      </button> */}
      <button onClick={() => changeLanguage('tr')} className="px-2 text-white hover:underline">
        TR
      </button>
    </div>
  );
}

export default LanguageSelector;
