// src/pages/NotFound.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NotFound() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto p-6 max-w-lg text-center bg-gray-800 rounded mt-10 shadow-lg">
      <h1 className="text-6xl font-bold text-red-500 mb-4">{t('page_not_found_title')}</h1>
      <h2 className="text-2xl font-semibold text-white mb-4">{t('page_not_found_heading')}</h2>
      <p className="text-gray-300 mb-6">
        {t('page_not_found_message')}
      </p>
      <Link
        to="/"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-block"
      >
        {t('back_to_home')}
      </Link>
    </div>
  );
}

export default NotFound;
