// src/pages/Home.jsx
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // AuthContext'i dahil ettik

function Home() {
  const { t } = useTranslation();
  const { isLoggedIn } = useContext(AuthContext); // Giriş yapılıp yapılmadığını kontrol ediyoruz

  const leagues = [
    { name: t('league_england'), logo: 'https://assets-us-01.kc-usercontent.com/31dbcbc6-da4c-0033-328a-d7621d0fa726/23ed1493-4652-43fb-ad21-2eedee2bac31/English%20Premier%20League_Logo.png?ver=28-06-2024?w=3840&q=75' },
    { name: t('league_spain'), logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/LaLiga_EA_Sports_2023_Vertical_Logo.svg/1200px-LaLiga_EA_Sports_2023_Vertical_Logo.svg.png' },
    { name: t('league_germany'), logo: 'https://upload.wikimedia.org/wikipedia/en/d/df/Bundesliga_logo_%282017%29.svg' },
    { name: t('league_italy'), logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Serie_A_logo_2022.svg/1200px-Serie_A_logo_2022.svg.png' },
    { name: t('league_norway'), logo: 'https://thefootytipster.com/wp-content/uploads/2017/02/Eliteserien-betting-tips.jpg' },
    { name: t('league_turkey'), logo: 'https://media04.ligtv.com.tr/img/news/2024/7/31/original/superliglogodikey.jpg' },
    { name: t('league_netherlands'), logo: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhj0DnA98jggi-OgTlqomvE8jvFyBkmKdyAdCEGhLRvmRMpGUTOcAv-7jezGYchOy-PcFcqJGiDvTWRk3507Z-HTQrB8TuBnPkqLXkP618d8C2nODMKyw8kuf2tbus7B0xRcAYqYkxRUO8/s550/all-new-eredivisie-logo+%25281%2529.jpg' },
  ];

  return (
    <div className="container mx-auto p-6 text-center bg-gray-900 text-white">
      <h1 className="text-4xl font-bold mb-4">{t('welcome')}</h1>
      <p className="text-lg mb-6">{t('subscribe_prompt')}</p>

      {/* Eğer kullanıcı giriş yapmamışsa "Kayıt Ol", giriş yapmışsa "Abone Ol" */}
      <Link to={isLoggedIn ? "/subscription-upgrade" : "/register"} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-10 inline-block">
        {isLoggedIn ? t('subscribe_button') : t('register')}
      </Link>

      <section className="mt-10">
        <h2 className="text-3xl font-bold mb-6">{t('leagues_covered')}</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {leagues.map((league, index) => (
            <div key={index} className="bg-gray-800 p-4 rounded shadow-md flex flex-col items-center">
              <img src={league.logo} alt={league.name} className="h-16 mb-4"/>
              <h3 className="text-xl font-semibold">{league.name}</h3>
            </div>
          ))}
          <div className="bg-gray-800 p-4 rounded shadow-md flex flex-col items-center">
            <h1 style={{'fontSize':64,"marginTop":36}} className="text-xl font-semibold">+28</h1>
          </div>
        </div>
      </section>

      <section className="mt-16">
        <h2 className="text-3xl font-bold mb-6">{t('why_choose_us')}</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-gray-800 p-4 rounded shadow-md">
            <h3 className="text-xl font-semibold mb-2">{t('accurate_predictions')}</h3>
            <p>{t('accurate_predictions_desc')}</p>
          </div>
          <div className="bg-gray-800 p-4 rounded shadow-md">
            <h3 className="text-xl font-semibold mb-2">{t('user_friendly_interface')}</h3>
            <p>{t('user_friendly_interface_desc')}</p>
          </div>
          <div className="bg-gray-800 p-4 rounded shadow-md">
            <h3 className="text-xl font-semibold mb-2">{t('data_driven_insights')}</h3>
            <p>{t('data_driven_insights_desc')}</p>
          </div>
        </div>
      </section>

      <section className="mt-16">
        <h2 className="text-3xl font-bold mb-6">{t('benefits_of_subscribing')}</h2>
        <ul className="list-disc list-inside bg-gray-800 p-6 rounded shadow-md">
          <li>{t('benefit_1')}</li>
          <li>{t('benefit_2')}</li>
          <li>{t('benefit_3')}</li>
        </ul>
      </section>

      <div className="mt-10">
        <Link to={isLoggedIn ? "/subscription-upgrade" : "/register"} className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-6 rounded inline-block">
          {isLoggedIn ? t('subscribe_button') : t('register')}
        </Link>
      </div>
    </div>
  );
}

export default Home;
